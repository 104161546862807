<template>
  <div>
    <Navigation />
    <div class="pa-5 flex space-between">
      <EmployeeFilters />
      <ElButton @click="$refs.employeeDialog.add()" type="primary"
        >Adauga Angajat</ElButton
      >
    </div>
    <!-- table -->
    <ResourceTable
      :query="query"
      :filter="filter"
      :default-sort="{ prop: 'data.name' }"
    >
      <ElTableColumn type="index" width="50" />
      <ElTableColumn prop="data.name" label="Nume" sortable />
      <ElTableColumn label="Creare" sortable sort-by="data.createdAt.seconds">
        <TimeAgo
          slot-scope="{ row }"
          v-if="row.data.createdAt"
          :date="row.data.createdAt.toDate()"
        />
      </ElTableColumn>
      <ElTableColumn
        label="Actualizare"
        sortable
        sort-by="data.updatedAt.seconds"
      >
        <TimeAgo
          slot-scope="{ row }"
          v-if="row.data.updatedAt"
          :date="row.data.updatedAt.toDate()"
        />
      </ElTableColumn>
      <ElTableColumn align="right">
        <template slot-scope="{ row }">
          <ElButton @click="$refs.employeeDialog.edit(row.id)" size="mini"
            >Modifica</ElButton
          >
        </template>
      </ElTableColumn>
    </ResourceTable>
    <!-- dialogs -->
    <EmployeeDialog ref="employeeDialog" />
  </div>
</template>

<script>
import FiltersMixin from "@/mixins/FiltersMixin";
import EmployeeDialog from "@/components/employees/EmployeeDialog";
import EmployeeFilters from "@/components/employees/EmployeeFilters";

export default {
  mixins: [FiltersMixin()],
  components: {
    EmployeeDialog,
    EmployeeFilters
  },
  computed: {
    query() {
      return this.$firestore().collection("employees");
    }
  },
  methods: {
    filter(row) {
      const { filters } = this;
      if (filters.name) {
        if (row.data.name.match(new RegExp(filters.name, "i")) === null) {
          return false;
        }
      }
      return true;
    }
  }
};
</script>
